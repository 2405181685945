<template>
  <div v-if="game">
    <!-- Show hidden white cards for current decider -->
    <WhiteCardGrid
      v-if="game.state === GameState.Playing && isCurrentPlayer && !revealWhiteCards"
      key="cwcPlaceholdersPlayer"
      :cards="[]"
      :clickable="false"
      :num-placeholders="playedWhiteCards.length"
    />
    <!-- Show hidden white cards of each player -->
    <WhiteCardGrid
      v-else-if="game.state === GameState.Playing && !revealWhiteCards"
      key="cwcPlaceholdersOthers"
      :cards="[]"
      :clickable="false"
      :num-placeholders="playedCardsByPlayer.length"
    />
    <!-- Show revealed clickable white cards to decider -->
    <WhiteCardGrid
      v-else-if="revealWhiteCards && isCurrentPlayer"
      key="cwcPlayer"
      :cards="playedWhiteCards"
      :chosen-white-card="chosenWhiteCard"
      :clickable="game.state === GameState.Selecting"
      :num-placeholders="0"
      :player-mapping="playerMapping"
      @select-card="scoreWinner"
    />
    <!-- Show revealed non-clickable white cards to players -->
    <WhiteCardGrid
      v-else-if="revealWhiteCards"
      key="cwcOthers"
      :cards="playedWhiteCards"
      :chosen-white-card="chosenWhiteCard"
      :clickable="false"
      :num-placeholders="0"
      :player-mapping="playerMapping"
    />
  </div>
</template>

<script lang="ts">
import {
    computed, defineComponent, reactive, watch,
} from 'vue';
import { useQuery } from 'villus';

import WhiteCardGrid from '@/components/WhiteCardGrid.vue';
import { useFetchSubList } from '@/useFetchSub';
import useMutation from '@/useMutation';
import useStore from '@/useStore';
import {
    AllPlayedCardsDocument, GameState, PlayedCardsByPlayerDocument,
    PlayedCardsWithPlayersDocument, PlayedWhiteCardDocument, ScoreWinnerDocument,
} from '@/generated/types';

export default defineComponent({
    name: 'PlayedWhiteCards',

    components: {
        WhiteCardGrid,
    },

    setup() {
        const [store, actions] = useStore();
        const { game, player, tempPlayedCards } = store;
        const {
            setPlayedCardsByPlayerCount, setPlayedWhiteCardsCount, resetTempPlayedCards,
        } = actions;

        const isCurrentPlayer = computed(() => {
            if (game.value && player.value) {
                return game.value.currentPlayer?.id === player.value.id;
            }
            return false;
        });

        const round = computed(() => {
            if (game.value?.round) {
                return game.value.round;
            }
            // Default if game not loaded
            return -1;
        });

        const gameId = computed(() => game.value?.id);
        const playerId = computed(() => player.value?.id);

        const isPaused = computed(() => !(
            isCurrentPlayer.value
            || (!!game.value?.state && [
                GameState.Selecting,
                GameState.Scoring,
                GameState.Scored,
            ].includes(game.value.state))
        ));
        const allPlayedCards = useFetchSubList(
            AllPlayedCardsDocument,
            PlayedWhiteCardDocument,
            reactive({
                gameId,
                round,
            }),
            isPaused,
        );

        const playedWhiteCards = computed(() => allPlayedCards.value.map((c) => c.whiteCard));

        watch(playedWhiteCards, () => {
            setPlayedWhiteCardsCount(playedWhiteCards.value.length);
        });

        const { data: playedCardsByPlayerData } = useQuery({
            query: PlayedCardsByPlayerDocument,
            variables: reactive({ playerId, round }),
        });
        watch(playedCardsByPlayerData, () => resetTempPlayedCards());

        const playedCardsByPlayer = computed(() => (
            (playedCardsByPlayerData.value?.playedCardsByPlayer ?? []).concat(
                tempPlayedCards.value,
            )
        ));
        watch(playedCardsByPlayer, () => {
            setPlayedCardsByPlayerCount(playedCardsByPlayer.value.length);
        });

        // TODO PR
        // eslint-disable-next-line
        const {
            data: playedCardsWithPlayersData,
            execute: fetchPlayedCardsWithPlayers,
        } = useQuery({
            query: PlayedCardsWithPlayersDocument,
            variables: reactive({ gameId, round }),
            fetchOnMount: game.value?.state === GameState.Scored,
            paused: true,
        });

        const playerMapping = computed(() => {
            const playedCardsWithPlayers = playedCardsWithPlayersData.value?.playedCardsWithPlayers;
            if (game.value?.state === GameState.Scored && playedCardsWithPlayers) {
                return Object.fromEntries(playedCardsWithPlayers.map((pc) => (
                    [pc.whiteCard.id, pc.player.name]
                )));
            }
            return {};
        });

        watch(() => game.value?.state, async (state) => {
            console.log(state);
            if (state === GameState.Scored) {
                await fetchPlayedCardsWithPlayers();
                resetTempPlayedCards();
            }
        });

        const revealWhiteCards = computed(() => {
            if (game.value) {
                return [GameState.Selecting, GameState.Scoring, GameState.Scored].includes(
                    game.value.state,
                );
            }
            return false;
        });

        const chosenWhiteCard = computed(() => {
            if (game.value && playedWhiteCards.value) {
                const chosenWhiteCards = allPlayedCards.value.filter((c) => c.chosen);
                if (chosenWhiteCards) {
                    return chosenWhiteCards[0]?.whiteCard;
                }
            }
            return null;
        });

        const doScoreWinner = useMutation(ScoreWinnerDocument);
        const scoreWinner = async (card: Record<string, string>) => {
            if (!game.value || !player.value) {
                console.error(`playWhiteCard: ${player.value ? 'game' : 'player'} not available`);
                return;
            }
            await doScoreWinner({
                cardId: card.cardId,
                gameId: game.value.id,
                playerId: player.value.id,
            });
        };

        return {
            chosenWhiteCard,
            GameState,
            game,
            isCurrentPlayer,
            playedCardsByPlayer,
            playedWhiteCards,
            playerMapping,
            revealWhiteCards,
            scoreWinner,
        };
    },
});

</script>
