<template>
  <div class="border m-5 rounded-lg">
    <h3 class="border-b font-bold p-3 text-xl">
      Players
    </h3>
    <ul class="py-3">
      <li
        v-for="player in sortedGamePlayers"
        :key="player.name"
        :class="{ activeplayer: isActivePlayer(player) }"
        class="px-5 py-2"
      >
        <span class="font-bold">{{ player.name }}:</span>
        {{ player.wonBlackCards.length }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import {
    computed, defineComponent,
} from 'vue';
import useStore from '@/useStore';

export default defineComponent({
    name: 'GameScoreboard',

    setup() {
        const [store] = useStore();
        const { game, gamePlayers } = store;

        function isActivePlayer(player: typeof gamePlayers['value'][0]): boolean {
            return game.value != null
                  && game.value.currentPlayer !== null
                  && player !== null
                  && player.id === game.value.currentPlayer?.id;
        }

        const sortedGamePlayers = computed(() => {
            const gps = gamePlayers.value;
            return gps.sort((a, b) => ((a.joinedDate > b.joinedDate ? 1 : -1)));
        });

        return {
            isActivePlayer,
            sortedGamePlayers,
        };
    },
});
</script>

<style>
.activeplayer {
  @apply bg-black text-white;
}
</style>
