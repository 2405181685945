<template>
  <div>
    <header
      :class="{ 'font-bold': expanded }"
      class="cursor-pointer"
      @click="$emit('expand')"
    >
      {{ gameName.name }}
    </header>
    <div
      v-if="expanded && hasAlreadyJoined"
      class="flow-root"
    >
      <router-link
        :to="{ name: 'game', params: { gameId: sessionUser.gameId,
                                       playerId: sessionUser.playerId }}"
        tag="button"
        class="btn-black"
      >
        Continue
      </router-link>
    </div>
    <JoinGameForm
      v-else-if="expanded"
      :game-name="gameName"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { ResultOf } from '@graphql-typed-document-node/core';
import { useQuery } from 'villus';
import { GameNamesDocument, SessionUserDocument } from '@/generated/types';

import JoinGameForm from '@/components/JoinGameForm.vue';

type GameName = ResultOf<typeof GameNamesDocument>['gameNames'][0];

export default defineComponent({
    name: 'GameListItem',

    components: {
        JoinGameForm,
    },

    props: {
        gameName: { required: true, type: Object as PropType<GameName> },
        expanded: Boolean,
    },

    setup(props) {
        const { data } = useQuery({ query: SessionUserDocument });
        const sessionUser = computed(() => data.value?.sessionUser);

        const hasAlreadyJoined = computed(
            () => (props.gameName.id === sessionUser.value?.gameId) && sessionUser.value?.playerId,
        );
        return { hasAlreadyJoined, sessionUser };
    },
});
</script>
