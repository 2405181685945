import {
    computed, ComputedRef, reactive, watch,
} from 'vue';
import { ResultOf } from '@graphql-typed-document-node/core';
import { useFetchSubItem, useFetchSubList } from './useFetchSub';
import {
    GameDocument, GameUpdatedDocument, GamePlayersDocument, GamePlayersUpdatedDocument,
    PlayedCardsByPlayerDocument, PlayerDocument, PlayerUpdatedDocument,
} from './generated/types';

type Game = ResultOf<typeof GameDocument>['game'];
type Player = ResultOf<typeof PlayerDocument>['player'];
type GamePlayers = ResultOf<typeof GamePlayersDocument>['gamePlayers'];
type PlayedCard = ResultOf<typeof PlayedCardsByPlayerDocument>['playedCardsByPlayer'][0];

interface State {
    game?: Game
    player?: Player
    gamePlayers: GamePlayers
    playedCardsByPlayerCount: number
    playedWhiteCardsCount: number
    tempPlayedCards: PlayedCard[]
}
const state = reactive<State>({
    gamePlayers: [],
    playedCardsByPlayerCount: 0,
    playedWhiteCardsCount: 0,
    tempPlayedCards: [],
});

interface Store {
    game: ComputedRef<Game | undefined>
    player: ComputedRef<Player | undefined>
    gamePlayers: ComputedRef<GamePlayers>
    playedCardsByPlayerCount: ComputedRef<number>
    playedWhiteCardsCount: ComputedRef<number>
    tempPlayedCards: ComputedRef<PlayedCard[]>
}

interface Actions {
    addTempPlayedCard: (card: PlayedCard) => void
    resetTempPlayedCards: () => void
    setPlayedCardsByPlayerCount: (count: number) => void
    setPlayedWhiteCardsCount: (count: number) => void
    setupStore: (gameId: string, playerId: string) => void
}

export default function useStore(): [Store, Actions] {
    const store = {
        game: computed(() => state.game),
        player: computed(() => state.player),
        gamePlayers: computed(() => state.gamePlayers),
        playedCardsByPlayerCount: computed(() => state.playedCardsByPlayerCount),
        playedWhiteCardsCount: computed(() => state.playedWhiteCardsCount),
        tempPlayedCards: computed(() => state.tempPlayedCards),
    };

    const actions = {
        addTempPlayedCard: (card: PlayedCard) => { state.tempPlayedCards.push(card); },
        resetTempPlayedCards: () => { state.tempPlayedCards = []; },
        setPlayedCardsByPlayerCount: (count: number) => { state.playedCardsByPlayerCount = count; },
        setPlayedWhiteCardsCount: (count: number) => { state.playedWhiteCardsCount = count; },
        setupStore: (gameId: string, playerId: string) => {
            const player = useFetchSubItem(
                PlayerDocument,
                PlayerUpdatedDocument,
                { id: playerId },
            );
            const game = useFetchSubItem(
                GameDocument,
                GameUpdatedDocument,
                { id: gameId },
            );
            const gamePlayers = useFetchSubList(
                GamePlayersDocument,
                GamePlayersUpdatedDocument,
                { gameId },
            );

            watch(player, (p) => {
                state.player = p;
            });
            watch(game, (g) => {
                state.game = g;
            });
            watch(gamePlayers, (gps) => {
                state.gamePlayers = gps;
            });
        },
    };

    return [store, actions];
}
