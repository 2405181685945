<template>
  <div class="flex flex-col h-full">
    <header>
      <div
        v-if="swUpdated"
        class="header-alert-blue"
      >
        New version available:
        <span
          class="cursor-pointer underline"
          @click="refreshApp"
        >
          Click to Reload
        </span>
      </div>
    </header>
    <div class="flex flex-col lg:flex-1 lg:flex-row">
      <div class="lg:flex-1 max-w-4xl mx-auto p-10">
        <router-view />
      </div>
      <div class="lg:order-first lg:w-1/5">
        <router-view name="left" />
      </div>
      <div
        id="right-sidebar"
        class="lg:w-1/4 xl:w-1/5"
      >
        <router-view name="right" />
      </div>
    </div>
    <footer class="max-w-xl mt-10 mx-auto pb-5 px-5 text-sm">
      <div class="text-center underline">
        <router-link :to="{ name: 'feedback'}">
          Give Feedback
        </router-link>
      </div>
      <a
        class="underline"
        href="https://cardsagainsthumanity.com"
        rel="noopener"
        target="_blank"
      >
        "Cards Against Humanity"
      </a>
      by <span class="italic">Cards Against Humanity LLC</span> is licensed under a
      <a
        class="underline"
        href="https://creativecommons.org/licenses/by-nc-sa/2.0/"
        rel="noopener"
        target="_blank"
      >
        Creative Commons BY-NC-SA 2.0 license
      </a>.
      This web version is in no way endorsed or sponsored by cardsagainsthumanity.com.
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import useVillusClient from './vue-apollo';
import useSW from './useSW';

export default defineComponent({
    name: 'App',
    setup() {
        useVillusClient();

        const { swUpdated, refreshApp } = useSW();

        return { swUpdated, refreshApp };
    },
});
</script>

<style>
@tailwind base;

@tailwind components;

.header-alert-blue {
  @apply bg-blue-100 text-blue-700 text-center p-4;
}

.btn-black {
  @apply shadow bg-black text-white font-bold py-2 px-4 rounded float-right cursor-pointer;
}
.btn-black:focus {
  @apply ring outline-none;
}
.btn-black:hover {
  @apply bg-gray-800;
}

.card {
  @apply font-bold p-3 rounded-lg shadow-md text-sm;
}
.card-big {
  @apply h-17rem m-5 w-48;
}
.card-small {
  @apply h-14rem m-1 w-40;
}
.card-black {
  @apply bg-black text-white;
}
.card-white {
  @apply border border-black;
}
.card-white-clickable:hover {
  @apply bg-gray-300 cursor-pointer;
}
.card-white-selected {
  @apply border-2;
}

@tailwind utilities;
</style>
