<template>
  <div class="flow-root">
    <LoadingBars :loading="loading" />
    <form
      class="w-full max-w-sm"
      @submit.prevent="joinGame"
    >
      <div
        v-if="joinError"
        class="flow-root bg-red-100 border border-red-400 text-red-700 px-4 py-3 my-5 rounded"
      >
        Joining game failed.
      </div>
      <div class="md:flex md:items-center mb-3">
        <div class="md:w-1/3">
          <label
            class="block md:text-right mb-1 md:mb-0 pr-4"
            for="inline-password-join"
          >
            Password
          </label>
        </div>
        <div class="md:w-2/3">
          <input
            id="inline-password-join"
            v-model="password"
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full
                   py-2 px-4 text-gray-700 leading-tight
                   focus:outline-none focus:bg-white focus:border-black"
            type="password"
            placeholder="******************"
            autocomplete="current-password"
          >
        </div>
      </div>
      <div class="md:flex md:items-center mb-3">
        <div class="md:w-1/3">
          <label
            class="block md:text-right mb-1 md:mb-0 pr-4"
            for="inline-player-name"
          >
            Player Name
          </label>
        </div>
        <div class="md:w-2/3">
          <input
            id="inline-player-name"
            v-model="playerName"
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full
                   py-2 px-4 text-gray-700 leading-tight
                   focus:outline-none focus:bg-white focus:border-black"
            type="text"
            placeholder="Player 1"
          >
        </div>
      </div>
      <input
        class="btn-black"
        type="submit"
        value="Join Game"
      >
    </form>
  </div>
</template>

<script lang="ts">
import {
    computed,
    defineComponent,
    PropType,
    ref,
    Ref,
} from 'vue';
import { ResultOf } from '@graphql-typed-document-node/core';
import { useMutation } from 'villus';
import { AuthForGameDocument, GameNamesDocument } from '@/generated/types';
import { useRouter } from 'vue-router';
import LoadingBars from './LoadingBars.vue';

type GameName = ResultOf<typeof GameNamesDocument>['gameNames'][0];

export default defineComponent({
    name: 'JoinGameForm',

    components: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        LoadingBars,
    },

    props: {
        gameName: { required: true, type: Object as PropType<GameName> },
    },

    setup(props) {
        const password = ref('');
        const playerName = ref('');
        const joinError = ref(false);
        const loading = ref(false);
        const router = useRouter();

        function validateJoinInput(): boolean {
            const errorMessages = [];

            if (!playerName) {
                errorMessages.push('Player name required.');
            }
            if (!password) {
                errorMessages.push('Password required.');
            }

            return errorMessages.length === 0;
        }

        const gameId = computed(() => props.gameName.id);
        const playerId: Ref<string> = ref('');

        const { data, error, execute: authForGameMutate } = useMutation(AuthForGameDocument);

        async function joinGame(): Promise<void> {
            if (validateJoinInput()) {
                loading.value = true;
                await authForGameMutate({
                    authInfo: {
                        gameId: gameId.value,
                        password: password.value,
                        playerName: playerName.value,
                    },
                });
                if (error.value) {
                    console.error('joinGame', error.value);
                    joinError.value = true;
                } else if (!data.value?.authForGame?.playerId) {
                    console.error('joinGame: playerId not returned');
                    joinError.value = true;
                } else {
                    password.value = '';
                    playerName.value = '';

                    playerId.value = data.value.authForGame.playerId;

                    router.push({
                        name: 'game',
                        params: { gameId: gameId.value, playerId: playerId.value },
                    }).catch((e: Error) => {
                        console.error('joinGame', e);
                    });
                }
                loading.value = false;
            }
        }

        return {
            joinGame, joinError, loading, password, playerName,
        };
    },
});
</script>
