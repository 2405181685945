import { createRouter, createWebHistory } from 'vue-router';
import HomeView from './views/HomeView.vue';

// route level code-splitting
// this generates a separate chunk (game.[hash].js) for this route
// which is lazy-loaded when the route is visited.

import GameView from './views/GameView.vue';
// const GameView = () => import(/* webpackChunkName: "game" */ './views/GameView.vue');
import GameScoreboard from './components/GameScoreboard.vue';
// const GameScoreboard = () => import(
//    /* webpackChunkName: "scoreboard" */ './components/GameScoreboard.vue'
// );
import GameChat from './components/GameChat.vue';
// const GameChat = () => import(/* webpackChunkName: "chat" */ './components/GameChat.vue');
import FeedbackView from './views/FeedbackView.vue';
// const FeedbackView = () => import(/* webpackChunkName: "feedback" */ './views/FeedbackView.vue');

export default createRouter({
    history: createWebHistory(import.meta.env?.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView,
        },
        {
            path: '/game/:gameId/player/:playerId',
            name: 'game',
            props: { default: true, left: true, right: true },

            components: {
                default: GameView,
                left: GameScoreboard,
                right: GameChat,
            },
        },
        {
            path: '/feedback',
            name: 'feedback',
            component: FeedbackView,
        },
        // Default to Home
        { path: '/:pathMatch(.*)*', redirect: { name: 'home' } },
    ],
});
