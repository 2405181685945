<template>
  <div class="border m-5 flex flex-col h-35vh lg:h-75vh rounded-lg">
    <div class="border-b font-bold p-3 text-xl">
      Chat
    </div>
    <ul
      v-chat-scroll
      class="border-b lg:flex-grow overflow-auto px-5 py-4"
    >
      <li
        v-for="chatMessage in chatMessages"
        :key="chatMessage.id"
        class="py-1"
      >
        <span class="font-bold">{{ chatMessage.playerName }}</span>: {{ chatMessage.message }}
      </li>
    </ul>
    <div class="box-border flex flex-init rounded-b-lg w-full">
      <input
        v-if="player"
        ref="messageInput"
        v-model="newMessage"
        class="flex-auto p-2 xl:p-4"
        placeholder="Message…"
        type="text"
        @focus="() => showPicker = false"
        @keyup.enter="sendMessage"
      >
      <div
        class="flex-none text-center pr-2 py-2 xl:py-4 w-8"
        @click="() => showPicker = !showPicker"
      >🙂</div>
    </div>
    <EmojiPicker
      v-if="showPicker"
      @use-emoji="useEmoji"
    />
  </div>
</template>

<script lang="ts">
import {
    computed, defineAsyncComponent, defineComponent, Ref, ref,
} from 'vue';
import { useMutation } from 'villus';

import useStore from '@/useStore';

import { useFetchSubList } from '@/useFetchSub';

import { ChatMessagesDocument, NewChatMessageDocument, SendChatMessageDocument } from '../generated/types';

export default defineComponent({
    name: 'GameChat',

    components: {
        // eslint-disable-next-line
        EmojiPicker: defineAsyncComponent(() => import('@/components/EmojiPicker.vue')),
    },

    props: {
        gameId: { required: true, type: String },
        playerId: { required: true, type: String },
    },

    setup(props) {
        const variables = {
            gameId: props.gameId,
        };

        const messageInput: Ref<HTMLFormElement|undefined> = ref();
        const newMessage = ref('');
        const showPicker = ref(false);

        const useEmoji = ((emoji: Record<string, string>) => {
            newMessage.value += emoji.native;
            showPicker.value = false;
            messageInput.value?.focus();
        });

        const rawChatMessages = useFetchSubList(
            ChatMessagesDocument, NewChatMessageDocument, variables,
        );

        const chatMessages = computed(() => {
            const cms = rawChatMessages.value;
            return cms.sort((a, b) => ((a.createdDate > b.createdDate) ? 1 : -1));
        });

        const [store] = useStore();
        const { player } = store;

        const { data, execute: sendChatMessage } = useMutation(SendChatMessageDocument);
        async function sendMessage() {
            if (newMessage.value) {
                const message = newMessage.value;
                newMessage.value = '';
                await sendChatMessage({
                    gameId: props.gameId,
                    message,
                    playerId: props.playerId,
                });
                if (!data.value?.sendChatMessage.success) {
                    newMessage.value = message;
                }
            }
            return null;
        }

        return {
            chatMessages, messageInput, newMessage, player, sendMessage, showPicker, useEmoji,
        };
    },
});
</script>
