<template>
  <div>
    <ul class="border rounded mx-auto w-4/5 mt-5 mb-10">
      <li
        v-for="(gameName, index) in gameNames"
        :key="gameName.id"
        class="p-2"
        :class="{ 'border-t': index !== null }"
      >
        <GameListItem
          :game-name="gameName"
          :expanded="expandedIndex === index"
          @expand="expandItem(index)"
        />
      </li>
    </ul>
    <NewGameForm
      :game-names="gameNames"
    />
  </div>
</template>

<script lang="ts">
import {
    defineComponent, ref, Ref,
} from 'vue';
import { useFetchSubList } from '@/useFetchSub';

import GameListItem from '@/components/GameListItem.vue';
import NewGameForm from '@/components/NewGameForm.vue';

import { GameNamesDocument, GameAddedDocument } from '../generated/types';

export default defineComponent({
    name: 'GameList',

    components: {
        GameListItem,
        NewGameForm,
    },

    setup() {
        const expandedIndex: Ref<number|null> = ref(null);

        function expandItem(index: number): void {
            if (index === expandedIndex.value) {
                expandedIndex.value = null;
            } else {
                expandedIndex.value = index;
            }
        }

        const gameNames = useFetchSubList(GameNamesDocument, GameAddedDocument, {});

        return {
            expandedIndex,
            expandItem,
            gameNames,
        };
    },
});
</script>
