/* tslint:disable:no-console */

import { register } from 'register-service-worker';

if (import.meta.env?.PROD) {
    const baseURL = import.meta.env.BASE_URL || '';
    register(`${baseURL}service-worker.js`, {
        ready(): void {
            console.log(
                'App is being served from cache by a service worker.\n'
                + 'For more details, visit https://goo.gl/AFskqB',
            );
        },
        registered(registration): void {
            setInterval((): void => {
                registration.update().catch((e) => {
                    console.error('registration.update failed', e);
                });
            }, 1000 * 60 * 60); // hourly checks
            console.log('Service worker has been registered.');
        },
        cached(): void {
            console.log('Content has been cached for offline use.');
        },
        updatefound(): void {
            console.log('New content is downloading.');
        },
        updated(registration): void {
            console.log('New content is available; please refresh.');
            document.dispatchEvent(
                new CustomEvent('swUpdated', { detail: registration }),
            );
        },
        offline(): void {
            console.log('No internet connection found. App is running in offline mode.');
        },
        error(error): void {
            console.error('Error during service worker registration:', error);
        },
    });
}
