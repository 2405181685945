import LogRocket from 'logrocket';

if (import.meta.env.PROD && !window.Cypress) {
    LogRocket.init('pcrg3r/cah', {
        console: {
            shouldAggregateConsoleErrors: true,
        },
        network: {
            isEnabled: false,
        },
    });
}

export default LogRocket;
