<template>
  <WhiteCardGrid
    :cards="playerWhiteCards"
    :clickable="cardsMissingByPlayer"
    :dropable="playerWhiteCards.length > 1"
    :num-placeholders="0"
    @drop-card="dropWhiteCard"
    @select-card="playWhiteCard"
  />
</template>

<script lang="ts">
import {
    computed, defineComponent, ref, Ref,
} from 'vue';
import { useMutation as useMutationVillus } from 'villus';
import WhiteCardGrid from '@/components/WhiteCardGrid.vue';
import useMutation from '@/useMutation';
import useStore from '@/useStore';
import { DropWhiteCardDocument, PlayWhiteCardDocument } from '@/generated/types';

export default defineComponent({
    name: 'PlayerHand',

    components: {
        WhiteCardGrid,
    },

    setup() {
        const [store, { addTempPlayedCard }] = useStore();
        const { game, playedCardsByPlayerCount, player } = store;

        const droppedCardIds: Ref<string[]> = ref([]);

        const playerWhiteCards = computed(() => {
            if (player.value) {
                return player.value.ownedWhiteCards.filter(
                    ({ id }) => !droppedCardIds.value.includes(id),
                );
            }
            return [];
        });

        const cardsMissingByPlayer = computed(() => {
            if (game.value && game.value.currentBlackCard) {
                return playedCardsByPlayerCount.value < game.value.currentBlackCard.pick;
            }
            return false;
        });

        const { data, error, execute: doPlayWhiteCard } = useMutationVillus(PlayWhiteCardDocument);
        const playWhiteCard = async (card: Record<string, string>) => {
            if (!game.value || !player.value) {
                console.error(`playWhiteCard: ${player.value ? 'game' : 'player'} not available`);
                return;
            }
            await doPlayWhiteCard({
                cardId: card.cardId,
                gameId: game.value.id,
                playerId: player.value.id,
            });
            if (error.value) {
                console.error('playWhiteCard', error.value);
            } else if (!data.value?.playWhiteCard.id) {
                console.error('playWhiteCard: playWhiteCard.id not available');
            } else {
                const wc = data.value.playWhiteCard;
                addTempPlayedCard({
                    __typename: 'WhiteCard',
                    id: wc.id,
                });
            }
        };

        const doDropWhiteCard = useMutation(DropWhiteCardDocument);

        const dropWhiteCard = async (card: Record<string, string>) => {
            if (!player.value) {
                console.error('dropWhiteCard: player not available');
                return;
            }
            const { data: dropData } = await doDropWhiteCard({
                cardId: card.cardId,
                playerId: player.value.id,
            });
            if (dropData.dropWhiteCard?.success) {
                droppedCardIds.value.push(card.cardId);
            }
        };

        return {
            cardsMissingByPlayer,
            dropWhiteCard,
            playerWhiteCards,
            playWhiteCard,
        };
    },
});

</script>
