import { createApp } from 'vue';
import VueChatScroll from 'vue-chat-scroll';

import App from './App.vue';
import LogRocket from './logrocket';
import router from './router';
import './registerServiceWorker';

const app = createApp(App);

app.config.errorHandler = (err, vm, info): void => {
    console.error('errrr', err);
    const lrErr = err instanceof Error ? err : new Error(String(err));
    LogRocket.captureException(lrErr, { extra: { info } });
};
app.config.warnHandler = (msg, vm, trace): void => {
    console.log('warrrn', msg);
    LogRocket.captureMessage(msg, { extra: { trace } });
};

app.use(router);

app.use(VueChatScroll);

app.mount('#app');
