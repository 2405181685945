<template>
  <form
    class="w-full max-w-sm"
    @submit.prevent="addGame"
  >
    <p v-if="errorMessages.length">
      <b>Please correct the following error(s):</b>
      <ul>
        <li
          v-for="(error, index) in errorMessages"
          :key="index"
        >
          {{ error }}
        </li>
      </ul>
    </p>
    <div class="md:flex md:items-center mb-6">
      <div class="md:w-1/3">
        <label
          class="block text-black font-bold md:text-right mb-1 md:mb-0 pr-4"
          for="inline-game-name"
        >
          Game Name
        </label>
      </div>
      <div class="md:w-2/3">
        <input
          id="inline-game-name"
          v-model="newGame.name"
          class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4
                 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-black"
          type="text"
        >
      </div>
    </div>
    <div class="md:flex md:items-center mb-6">
      <div class="md:w-1/3">
        <label
          class="block text-black font-bold md:text-right mb-1 md:mb-0 pr-4"
          for="inline-password"
        >
          Password
        </label>
      </div>
      <div class="md:w-2/3">
        <input
          id="inline-password"
          v-model="newGame.password"
          class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4
                 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-black"
          type="password"
          placeholder="******************"
          autocomplete="new-password"
        >
      </div>
    </div>
    <div class="md:flex md:items-center mb-6">
      <div class="md:w-1/3">
        <label
          class="block text-black font-bold md:text-right mb-1 md:mb-0 pr-4"
          for="inline-password-confirmation"
        >
          Password Confirmation
        </label>
      </div>
      <div class="md:w-2/3">
        <input
          id="inline-password-confirmation"
          v-model="passwordConfirmation"
          class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4
                 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-black"
          type="password"
          placeholder="******************"
          autocomplete="new-password"
        >
      </div>
    </div>
    <div class="md:flex md:items-center">
      <div class="md:w-1/3" />
      <div class="md:w-2/3">
        <input
          class="btn-black"
          id="create-game-button"
          type="submit"
          value="Create Game"
        >
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import {
    defineComponent,
    PropType,
    ref,
    Ref,
    reactive,
} from 'vue';
import { ResultOf } from '@graphql-typed-document-node/core';
import { useMutation } from 'villus';
import { AddGameDocument, GameNamesDocument } from '@/generated/types';

type GameName = ResultOf<typeof GameNamesDocument>['gameNames'][0];

export default defineComponent({
    name: 'NewGameForm',

    props: {
        gameNames: { required: true, type: Object as PropType<GameName[]> },
    },

    setup(props) {
        const newGame = reactive({
            name: '',
            password: '',
        });
        const passwordConfirmation = ref('');
        const errorMessages: Ref<string[]> = ref([]);

        function validateGameInput(): boolean {
            errorMessages.value = [];

            if (!newGame.name) {
                errorMessages.value.push('Game name required.');
            }
            if (props.gameNames && props.gameNames.map((gn) => gn.name).includes(newGame.name)) {
                errorMessages.value.push('Game name already exists.');
            }
            if (!newGame.password) {
                errorMessages.value.push('Password required.');
            } else if (newGame.password.length < 8) {
                errorMessages.value.push('Password must have at least 8 characters.');
            }
            if (newGame.password !== passwordConfirmation.value) {
                errorMessages.value.push('Password does not match confirmation.');
            }
            if (newGame.password === newGame.name) {
                errorMessages.value.push('Password can not be equal to Game name.');
            }

            return errorMessages.value.length === 0;
        }

        const { data, error, execute: addGameMutate } = useMutation(AddGameDocument);

        async function addGame(): Promise<void> {
            if (validateGameInput()) {
                await addGameMutate({ game: newGame });
                if (error.value) {
                    console.error('addGame', error.value);
                } else if (!data.value?.addGame.id) {
                    console.error('addGame: No game.id returned');
                } else {
                    newGame.name = '';
                    newGame.password = '';
                    passwordConfirmation.value = '';
                }
            }
        }

        return {
            addGame,
            errorMessages,
            newGame,
            passwordConfirmation,
        };
    },
});
</script>
