// import { OperationOptions, SubscriptionClient } from 'subscriptions-transport-ws';
import { DocumentNode, getOperationAST, parse, print } from 'graphql';
import { createClient, ExecutionResult, Sink } from 'graphql-ws';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { defaultPlugins, handleSubscriptions, ObserverLike, StandardOperationResult, SubscriptionForwarder, useClient } from 'villus';

// Create the subscription websocket link
const host = import.meta.env.PROD ? 'cah-api.bitraten.net' : window.location.host;
const proto = host.startsWith('localhost') ? 'ws' : 'wss';
const wsURL = `${proto}://${host}/graphql`;

// Old
/*
const subscriptionClient = new SubscriptionClient(
    wsURL,
    {
        reconnect: true,
        connectionParams: {},
    },
);
const forwardSubscription = (operation: OperationOptions) => subscriptionClient.request(operation);
*/

//New
const wsClient = createClient({ on: { error: (e) => console.error(e) }, url: wsURL });

function villusToGraphQlWS<TData = any>(obs: ObserverLike<StandardOperationResult<TData>>): Sink<ExecutionResult<TData, unknown>> {
    const complete = (obs.complete ?? (() => {}));
    const error = (obs.error ?? (() => {}));
    const next = (obs.next ?? (() => {}));
    return {
        complete,
        error,
        next,
    };
}

const queryToString = (query: string | DocumentNode | TypedDocumentNode<unknown, unknown>) => (
    typeof(query) === 'string' ? query : print(query)
);

const subscriptionForwarder: SubscriptionForwarder = (operation) => {
    const query = operation.query;
    const subscribe = (obs: SubscriptionObserver) => {
        const unsubscribe = wsClient.subscribe(
            {
                query: queryToString(query),
                variables: operation.variables,
            },
            villusToGraphQlWS(obs),
        );
        if (window.Cypress) {
            const queryDocumentNode = typeof query === 'string' ? parse(query) : query;
            const operationName = getOperationAST(queryDocumentNode)?.name?.value;
            if (operationName) {
                if (!window.subscriptionObs) {
                    window.subscriptionObs = {};
                }
                window.subscriptionObs[operationName] = obs;
            }
        }
        return { unsubscribe };
    };
    return {
        subscribe,
    };
};

const useVillusClient: () => void = () => {
    useClient({
        url: import.meta.env.PROD ? 'https://cah-api.bitraten.net/graphql' : '/graphql',
        cachePolicy: 'network-only',
        use: [handleSubscriptions(subscriptionForwarder), ...defaultPlugins()],
    });
};

export default useVillusClient;
