import { CombinedError, useMutation as useMutationVillus } from 'villus';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { getOperationAST } from 'graphql';

import { Mutation } from '@/generated/types';

type MutationTypeKey = keyof Omit<Mutation, '__typename'>;
type MutationResult<Y> = { __typename?: string } & {
    [key in MutationTypeKey]?: Y;
};

interface HasSuccess {
    success: boolean;
}

export default function useMutation<
    V, R extends HasSuccess,
>(
    mutationQuery: TypedDocumentNode<MutationResult<R>, V>,
): (vs: V) => Promise<{
        data: MutationResult<R> | null;
        error: CombinedError | null;
    }> {
    const mutationKey = getOperationAST(mutationQuery)?.name?.value as MutationTypeKey | undefined;

    if (!mutationKey) throw Error('mutationKey not found');

    const { data, error, execute: executeRaw } = useMutationVillus(mutationQuery);

    const execute = async (vs: V) => {
        const res = await executeRaw(vs);

        if (error.value) {
            console.error(mutationKey, error.value);
        } else if (!(
            data.value
            && data.value[mutationKey] !== undefined
            && data.value[mutationKey]?.success)) {
            console.error(`${mutationKey}: Not successful`);
        }

        return res;
    };
    return execute;
}
