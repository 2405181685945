import { useQuery, useSubscription } from 'villus';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { getOperationAST } from 'graphql';
import {
    computed, Ref, ref, watch,
} from 'vue';

import { Query, Subscription } from '@/generated/types';

type FetchTypeKey = keyof Omit<Query, '__typename'>;
type FetchResultItem<Y> = { __typename?: string } & {
    [key in FetchTypeKey]?: Y;
};
type FetchResultList<Y> = { __typename?: string } & {
    [key in FetchTypeKey]?: Array<Y>;
};

type SubTypeKey = keyof Omit<Subscription, '__typename'>;
type SubResult<Y> = { __typename?: string } & {
    [key in SubTypeKey]?: Y;
};

interface MaybeHasId {
    id?: string;
}

const pushUpsert = <T extends MaybeHasId>(list: Array<T>, item: T) => {
    let index = -1;
    if (item.id) {
        for (let i = 0; i < list.length; i += 1) {
            if (list[i].id === item.id) {
                index = i;
            }
        }
    }
    const updatedList = list;
    if (index >= 0) {
        updatedList[index] = item;
    } else {
        updatedList.push(item);
    }
    return updatedList;
};

export function useFetchSubList<
    V, R extends MaybeHasId,
>(
    fetchQuery: TypedDocumentNode<FetchResultList<R>, V>,
    subQuery: TypedDocumentNode<SubResult<R>, V>,
    variables: V,
    isPaused: Ref<boolean> = ref(false),
): Ref<R[]> {
    const fetchKey = getOperationAST(fetchQuery)?.name?.value as FetchTypeKey | undefined;
    const subKey = getOperationAST(subQuery)?.name?.value as SubTypeKey | undefined;

    // TODO PR
    // eslint-disable-next-line
    const {
        data: fetchData, error,
    } = useQuery({
        query: fetchQuery,
        variables,
        paused: isPaused,
    });

    watch(error, () => {
        if (error) {
            console.error(`error while fetching ${fetchKey ?? 'undefined'}: ${JSON.stringify(error)}`);
        }
    });

    const fetchedResult = computed(() => {
        if (fetchData.value && fetchKey) {
            return fetchData.value[fetchKey] ?? [];
        }
        return [];
    });

    const combinedResults: Ref<R[]> = ref([]);
    watch(fetchedResult, () => {
        // Delete subscription messages when fetching all messages
        combinedResults.value = fetchedResult.value;
    });

    const { data: subData } = useSubscription({
        query: subQuery,
        variables,
        paused: isPaused,
    });

    watch(subData, (incoming) => {
        const incomingResult = (subKey ? incoming[subKey] : undefined) as R | undefined;
        if (incomingResult) {
            combinedResults.value = pushUpsert(combinedResults.value, incomingResult);
        } else {
            console.error(`'${JSON.stringify(subKey)}' not found in ${JSON.stringify(incoming)}`);
        }
    });

    watch(isPaused, () => {
        if (isPaused.value) {
            console.log(`pausing '${fetchKey ?? ''}'`);
        } else {
            console.log(`resuming '${fetchKey ?? ''}'`);
        }
    });

    return combinedResults;
}

export function useFetchSubItem<
    V, R,
>(
    fetchQuery: TypedDocumentNode<FetchResultItem<R>, V>,
    subQuery: TypedDocumentNode<SubResult<R>, V>,
    variables: V,
): Ref<R | undefined> {
    const fetchKey = getOperationAST(fetchQuery)?.name?.value as FetchTypeKey | undefined;
    const subKey = getOperationAST(subQuery)?.name?.value as SubTypeKey | undefined;

    const { data: fetchData, isFetching } = useQuery({
        query: fetchQuery,
        variables,
    });

    const fetchedResult = computed(() => {
        if (fetchData.value && fetchKey) {
            return fetchData.value[fetchKey];
        }
        return undefined;
    });

    const combinedResult: Ref<R | undefined> = ref();
    watch(isFetching, () => {
        // Delete subscription result when fetching new result
        combinedResult.value = fetchedResult.value;
    });

    const { data: subData } = useSubscription({
        query: subQuery,
        variables,
    });

    watch(subData, (incoming) => {
        const incomingResult = (subKey ? incoming[subKey] : undefined) as R | undefined;
        if (incomingResult) {
            combinedResult.value = incomingResult;
        } else {
            console.error(`'${JSON.stringify(subKey)}' not found in ${JSON.stringify(incoming)}`);
        }
    });

    return combinedResult;
}
