<template>
  <div class="max-w-xl mx-auto px-10">
    <label
      class="block text-black font-bold md:text-right mb-1 md:mb-0 pr-4"
      for="inline-game-name"
    >
      <span v-if="thanks">Thanks!
      </span>
      <span v-else>What should still be improved about CAH?</span>
    </label>
    <a
      class="underline"
      href="#"
      @click="router.go(-1)"
    >
      Back
    </a>
    <textarea
      id="feedback"
      :disabled="thanks"
      v-model="feedback"
      name="feedback"
      placeholder="Feedback…"
      rows="5"
      cols="33"
      class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4
                 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-black">
      Feedback
    </textarea>
    <input
      class="btn-black"
      @click="submitFeedback"
      type="submit"
      v-if="!thanks"
      value="Submit"
    >
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import LogRocket from '@/logrocket';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'FeedbackView',

    setup: (() => {
        const feedback = ref('');
        const thanks = ref(false);
        const router = useRouter();

        const submitFeedback = () => {
            if (feedback.value) {
                LogRocket.captureMessage(feedback.value);
                thanks.value = true;
            }
        };

        return {
            feedback, router, submitFeedback, thanks,
        };
    }),
});
</script>
