<template>
  <div class="home">
    <div v-if="game">
      <div>{{ nextStep }}</div>
      <div v-if="game.state === GameState.Created">
        <div v-if="player">
          Hi {{ player.name }}, welcome to {{ game.name }}!
        </div>
        <button
          class="btn-black"
          @click="startGame"
        >
          Start Game
        </button>
      </div>
      <div v-else>
        <div class="border-b-2 lg:flex lg:flex-row mb-10">
          <div
            v-if="game.currentBlackCard"
            class="card card-big card-black lg:flex-shrink-0 mt-0 mx-auto"
          >
            <span
              v-html="game.currentBlackCard.text"
            />
          </div>
          <PlayedWhiteCards />
        </div>
        <div v-if="[GameState.Playing].includes(game.state)">
          <div v-if="game.currentPlayer && player && game.currentPlayer.id == player.id">
            It's your turn to choose the funniest card this round.
            {{ playedWhiteCardsCount }}/{{ expectedWhiteCards }} are already there.
            <button
              v-if="game.state === GameState.Playing && playedWhiteCardsCount > 0"
              class="btn-black"
              @click="revealCWC"
            >
              Reveal Cards
            </button>
          </div>
          <PlayerHand v-else />
        </div>
        <div v-else-if="game.state === GameState.Scored">
          <button
            class="btn-black"
            @click="startGame"
          >
            Next Round
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      Loading…
    </div>
  </div>
</template>

<script lang="ts">
import {
    computed, defineComponent, ref,
} from 'vue';
import useMutation from '@/useMutation';

import PlayedWhiteCards from '@/components/PlayedWhiteCards.vue';
import PlayerHand from '@/components/PlayerHand.vue';
import { GameState, RevealWhiteCardsDocument, DistributeCardsDocument } from '@/generated/types';
import LogRocket from '@/logrocket';
import useStore from '@/useStore';

export default defineComponent({
    name: 'GameView',

    components: {
        PlayedWhiteCards,
        PlayerHand,
    },

    props: {
        gameId: { required: true, type: String },
        playerId: { required: true, type: String },
    },

    setup(props) {
        const [store, { setupStore }] = useStore();
        setupStore(props.gameId, props.playerId);

        LogRocket.identify(props.playerId, {
            gameId: props.gameId,
        });

        const {
            game, gamePlayers, playedCardsByPlayerCount, playedWhiteCardsCount, player,
        } = store;

        // eslint-disable-next-line
        const nextStep = computed(() => {
            /*
            if (game.value?.state === GameState.Playing) {
                return 'Check if is player';
            }
            */
            return '';
        });

        const doRevealCWC = useMutation(RevealWhiteCardsDocument);
        const revealCWC = () => doRevealCWC({ gameId: props.gameId });
        const doStartGame = useMutation(DistributeCardsDocument);
        const startGame = () => doStartGame({ gameId: props.gameId });

        const expectedWhiteCards = computed(() => {
            if (game.value && game.value.currentBlackCard && gamePlayers.value) {
                return (gamePlayers.value.length - 1) * game.value.currentBlackCard.pick;
            }
            return 0;
        });

        return {
            GameState,
            game,
            nextStep,
            player,
            startGame, // CREATED/SCORED => DISTRIBUTING => PLAYING
            revealCWC, // PLAYING => SELECTING
            playedWhiteCardsCount,
            playedCardsByPlayerCount,
            // scoreWinner, // SELECTING => SCORING => SCORED
            expectedWhiteCards,
        };
    },
});
</script>
