<template>
  <div class="max-w-xl mx-auto px-10">
    <SetupView v-if="(setupStatus && !setupStatus.isInitialized)" />
    <div v-else>
      <h1 class="font-bold text-xl mb-2">
        Games
      </h1>
      <GameList />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useQuery } from 'villus';

import { GetSetupStatusDocument } from '@/generated/types';

import GameList from '@/components/GameList.vue'; // @ is an alias to /src
import SetupView from '@/views/SetupView.vue'; // @ is an alias to /src

export default defineComponent({
    name: 'HomeView',
    components: {
        GameList,
        SetupView,
    },
    setup() {
        const { data } = useQuery({ query: GetSetupStatusDocument });
        const setupStatus = computed(() => data?.value?.setupStatus);

        return { setupStatus };
    },
});
</script>
