<template>
  <div class="max-w-xl mx-auto px-10">
    <h1>Setup</h1>

    <form
      class="w-full max-w-sm"
      @submit.prevent="onInitialize"
    >
      <div
        v-if="initializeError"
        class="flow-root bg-red-100 border border-red-400 text-red-700 px-4 py-3 my-5 rounded"
      >
        Initializing failed.
      </div>
      <input
        class="btn-black"
        type="submit"
        value="Initialize"
      >
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import useMutation from '@/useMutation';
import { InitializeDocument } from '../generated/types';

export default defineComponent({
    name: 'SetupView',

    setup() {
        const execute = useMutation(InitializeDocument);
        const initializeError = ref(false);
        const router = useRouter();

        async function onInitialize() {
            const { data } = await execute({});
            if (data.initialize?.success) {
                router.go(0);
            } else {
                initializeError.value = true;
            }
        }

        return { onInitialize, initializeError };
    },
});
</script>
