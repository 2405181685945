<template>
  <ul class="flex flex-row flex-wrap justify-center">
    <li
      v-for="card in cardsToShow"
      :key="card.id"
      class="card card-small card-white relative"
      :class="{'card-white-clickable': clickable,
               'card-white-selected': card.id === chosenWhiteCardId}"
      @click="selectCard(card)"
    >
      <div
        v-html="card.text"
      />
      <div
        v-if="cardPlayer(card.id)"
        class="absolute bottom-0 font-normal"
      >
        {{ cardPlayer(card.id) }}
      </div>
      <div
        v-if="dropable"
        class="drop-button"
        @click.stop="dropCard(card)"
      >
        🗑
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import {
    computed,
    defineComponent,
    PropType,
} from 'vue';
import { ResultOf } from '@graphql-typed-document-node/core';

import { PlayedCardsByPlayerDocument } from '@/generated/types';

type WhiteCard = ResultOf<typeof PlayedCardsByPlayerDocument>['playedCardsByPlayer'][0];

export default defineComponent({
    name: 'WhiteCardGrid',
    props: {
        cards: { required: true, type: Object as PropType<WhiteCard[]> },
        chosenWhiteCard: { default: () => null, type: (Object as PropType<WhiteCard | null>) },
        clickable: Boolean,
        dropable: { default: false, type: Boolean },
        numPlaceholders: { default: () => 0, type: Number },
        playerMapping: { default: () => {}, type: Object as PropType<Record<string, string>> },
    },
    emits: [ 'select-card', 'drop-card' ],

    setup(props, { emit }) {
        const cardsToShow = computed(() => {
            const placeholderIds = Array.from(Array(props.numPlaceholders).keys());
            const placeholderCards: WhiteCard[] = placeholderIds.map((id) => ({
                id: id.toString(),
            }));

            return props.cards.concat(placeholderCards);
        });

        function selectCard(card: WhiteCard): void {
            if (props.clickable) {
                emit('select-card', { cardId: card.id });
            }
        }

        function dropCard(card: WhiteCard): void {
            if (props.dropable) {
                console.log('1', card);
                emit('drop-card', { cardId: card.id });
            }
        }

        const chosenWhiteCardId = computed(() => (
            props.chosenWhiteCard ? props.chosenWhiteCard.id : null
        ));

        const cardPlayer = (cardId: string) => {
            if (props.playerMapping) {
                return props.playerMapping[cardId];
            }
            return null;
        };

        return {
            cardsToShow,
            chosenWhiteCardId,
            cardPlayer,
            dropCard,
            selectCard,
        };
    },
});

</script>

<style>

.drop-button {
  @apply absolute border bottom-0 font-normal p-3 right-0 rounded-lg;
}

.drop-button:hover {
  @apply bg-gray-300 cursor-pointer;
}

</style>
