import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: { input: any; output: any; }
};

export type AuthInfoInput = {
  gameId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  playerName: Scalars['String']['input'];
};

export type BlackCard = {
  __typename?: 'BlackCard';
  deck: Deck;
  id: Scalars['ID']['output'];
  pick: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  createdDate: Scalars['DateTime']['output'];
  game: Game;
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  playerName: Scalars['String']['output'];
};

export type Deck = {
  __typename?: 'Deck';
  blackCards: Array<BlackCard>;
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  whiteCards: Array<WhiteCard>;
};

export type Game = {
  __typename?: 'Game';
  createdDate: Scalars['DateTime']['output'];
  currentBlackCard?: Maybe<BlackCard>;
  currentPlayer?: Maybe<Player>;
  decks?: Maybe<Array<Deck>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  round: Scalars['Int']['output'];
  state: GameState;
};

export type GameName = {
  __typename?: 'GameName';
  createdDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  state: GameState;
};

export enum GameState {
  Created = 'CREATED',
  Distributing = 'DISTRIBUTING',
  Playing = 'PLAYING',
  Scored = 'SCORED',
  Scoring = 'SCORING',
  Selecting = 'SELECTING'
}

export type Mutation = {
  __typename?: 'Mutation';
  addGame: GameName;
  authForGame: SessionUser;
  distributeCards: MutationResult;
  dropWhiteCard: MutationResult;
  initialize: MutationResult;
  playWhiteCard: WhiteCard;
  reset: MutationResult;
  revealWhiteCards: MutationResult;
  scoreWinner: MutationResult;
  sendChatMessage: MutationResult;
};


export type MutationAddGameArgs = {
  game: NewGameInput;
};


export type MutationAuthForGameArgs = {
  authInfo: AuthInfoInput;
};


export type MutationDistributeCardsArgs = {
  gameId: Scalars['String']['input'];
};


export type MutationDropWhiteCardArgs = {
  cardId: Scalars['String']['input'];
  playerId: Scalars['String']['input'];
};


export type MutationPlayWhiteCardArgs = {
  cardId: Scalars['String']['input'];
  gameId: Scalars['String']['input'];
  playerId: Scalars['String']['input'];
};


export type MutationRevealWhiteCardsArgs = {
  gameId: Scalars['String']['input'];
};


export type MutationScoreWinnerArgs = {
  cardId: Scalars['String']['input'];
  gameId: Scalars['String']['input'];
  playerId: Scalars['String']['input'];
};


export type MutationSendChatMessageArgs = {
  gameId: Scalars['String']['input'];
  message: Scalars['String']['input'];
  playerId: Scalars['String']['input'];
};

export type MutationResult = {
  __typename?: 'MutationResult';
  success: Scalars['Boolean']['output'];
};

export type NewGameInput = {
  deckIds?: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type PlayedWhiteCard = {
  __typename?: 'PlayedWhiteCard';
  chosen: Scalars['Boolean']['output'];
  game: Game;
  id: Scalars['ID']['output'];
  player: Player;
  round: Scalars['Int']['output'];
  whiteCard: WhiteCard;
};

export type Player = {
  __typename?: 'Player';
  game: Game;
  id: Scalars['ID']['output'];
  joinedDate: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  ownedWhiteCards: Array<WhiteCard>;
  state: PlayerState;
  wonBlackCards: Array<BlackCard>;
};

export enum PlayerState {
  Idle = 'IDLE',
  Playing = 'PLAYING'
}

export type PublicPlayer = {
  __typename?: 'PublicPlayer';
  game: Game;
  id: Scalars['ID']['output'];
  joinedDate: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  state: PlayerState;
  wonBlackCards: Array<BlackCard>;
};

export type Query = {
  __typename?: 'Query';
  allPlayedCards: Array<PlayedWhiteCard>;
  chatMessages: Array<ChatMessage>;
  game: Game;
  gameNames: Array<GameName>;
  gamePlayers: Array<PublicPlayer>;
  playedCardsByPlayer: Array<WhiteCard>;
  playedCardsWithPlayers: Array<PlayedWhiteCard>;
  player: Player;
  sessionUser: SessionUser;
  setupStatus: SetupStatus;
};


export type QueryAllPlayedCardsArgs = {
  gameId: Scalars['String']['input'];
  round: Scalars['Int']['input'];
};


export type QueryChatMessagesArgs = {
  gameId: Scalars['String']['input'];
};


export type QueryGameArgs = {
  id: Scalars['String']['input'];
};


export type QueryGamePlayersArgs = {
  gameId: Scalars['String']['input'];
};


export type QueryPlayedCardsByPlayerArgs = {
  playerId: Scalars['String']['input'];
  round: Scalars['Int']['input'];
};


export type QueryPlayedCardsWithPlayersArgs = {
  gameId: Scalars['String']['input'];
  round: Scalars['Int']['input'];
};


export type QueryPlayerArgs = {
  id: Scalars['String']['input'];
};

export type SessionUser = {
  __typename?: 'SessionUser';
  gameId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  playerId?: Maybe<Scalars['String']['output']>;
};

export type SetupStatus = {
  __typename?: 'SetupStatus';
  blackCardCount: Scalars['Float']['output'];
  isInitialized: Scalars['Boolean']['output'];
  whiteCardCount: Scalars['Float']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  gameAdded: GameName;
  gamePlayersUpdated: PublicPlayer;
  gameUpdated: Game;
  newChatMessage: ChatMessage;
  playedWhiteCard: PlayedWhiteCard;
  playerUpdated: Player;
};


export type SubscriptionGamePlayersUpdatedArgs = {
  gameId: Scalars['String']['input'];
};


export type SubscriptionGameUpdatedArgs = {
  id: Scalars['String']['input'];
};


export type SubscriptionNewChatMessageArgs = {
  gameId: Scalars['String']['input'];
};


export type SubscriptionPlayedWhiteCardArgs = {
  gameId: Scalars['String']['input'];
  round: Scalars['Int']['input'];
};


export type SubscriptionPlayerUpdatedArgs = {
  id: Scalars['String']['input'];
};

export type WhiteCard = {
  __typename?: 'WhiteCard';
  deck: Deck;
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
};

export type GetSetupStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSetupStatusQuery = { __typename?: 'Query', setupStatus: { __typename?: 'SetupStatus', blackCardCount: number, whiteCardCount: number, isInitialized: boolean } };

export type PlayerQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type PlayerQuery = { __typename?: 'Query', player: { __typename?: 'Player', id: string, name: string, ownedWhiteCards: Array<{ __typename?: 'WhiteCard', id: string, text: string }>, wonBlackCards: Array<{ __typename?: 'BlackCard', id: string }> } };

export type PlayerUpdatedSubscriptionVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type PlayerUpdatedSubscription = { __typename?: 'Subscription', playerUpdated: { __typename?: 'Player', id: string, name: string, ownedWhiteCards: Array<{ __typename?: 'WhiteCard', id: string, text: string }>, wonBlackCards: Array<{ __typename?: 'BlackCard', id: string }> } };

export type ChatMessagesQueryVariables = Exact<{
  gameId: Scalars['String']['input'];
}>;


export type ChatMessagesQuery = { __typename?: 'Query', chatMessages: Array<{ __typename?: 'ChatMessage', createdDate: any, id: string, playerName: string, message: string }> };

export type NewChatMessageSubscriptionVariables = Exact<{
  gameId: Scalars['String']['input'];
}>;


export type NewChatMessageSubscription = { __typename?: 'Subscription', newChatMessage: { __typename?: 'ChatMessage', createdDate: any, id: string, playerName: string, message: string } };

export type SendChatMessageMutationVariables = Exact<{
  message: Scalars['String']['input'];
  gameId: Scalars['String']['input'];
  playerId: Scalars['String']['input'];
}>;


export type SendChatMessageMutation = { __typename?: 'Mutation', sendChatMessage: { __typename?: 'MutationResult', success: boolean } };

export type GameNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GameNamesQuery = { __typename?: 'Query', gameNames: Array<{ __typename?: 'GameName', id: string, name: string, createdDate: any, state: GameState }> };

export type GameAddedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GameAddedSubscription = { __typename?: 'Subscription', gameAdded: { __typename?: 'GameName', id: string, name: string, createdDate: any } };

export type GameQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GameQuery = { __typename?: 'Query', game: { __typename?: 'Game', id: string, name: string, round: number, state: GameState, currentPlayer?: { __typename?: 'Player', id: string } | null, currentBlackCard?: { __typename?: 'BlackCard', id: string, pick: number, text: string } | null } };

export type GameUpdatedSubscriptionVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GameUpdatedSubscription = { __typename?: 'Subscription', gameUpdated: { __typename?: 'Game', id: string, name: string, round: number, state: GameState, currentPlayer?: { __typename?: 'Player', id: string } | null, currentBlackCard?: { __typename?: 'BlackCard', id: string, pick: number, text: string } | null } };

export type GamePlayersQueryVariables = Exact<{
  gameId: Scalars['String']['input'];
}>;


export type GamePlayersQuery = { __typename?: 'Query', gamePlayers: Array<{ __typename?: 'PublicPlayer', id: string, joinedDate: any, name: string, wonBlackCards: Array<{ __typename?: 'BlackCard', id: string }> }> };

export type GamePlayersUpdatedSubscriptionVariables = Exact<{
  gameId: Scalars['String']['input'];
}>;


export type GamePlayersUpdatedSubscription = { __typename?: 'Subscription', gamePlayersUpdated: { __typename?: 'PublicPlayer', id: string, joinedDate: any, name: string, wonBlackCards: Array<{ __typename?: 'BlackCard', id: string }> } };

export type PlayedCardsByPlayerQueryVariables = Exact<{
  playerId: Scalars['String']['input'];
  round: Scalars['Int']['input'];
}>;


export type PlayedCardsByPlayerQuery = { __typename?: 'Query', playedCardsByPlayer: Array<{ __typename?: 'WhiteCard', id: string }> };

export type AllPlayedCardsQueryVariables = Exact<{
  gameId: Scalars['String']['input'];
  round: Scalars['Int']['input'];
}>;


export type AllPlayedCardsQuery = { __typename?: 'Query', allPlayedCards: Array<{ __typename?: 'PlayedWhiteCard', id: string, chosen: boolean, whiteCard: { __typename?: 'WhiteCard', id: string, text: string } }> };

export type PlayedWhiteCardSubscriptionVariables = Exact<{
  gameId: Scalars['String']['input'];
  round: Scalars['Int']['input'];
}>;


export type PlayedWhiteCardSubscription = { __typename?: 'Subscription', playedWhiteCard: { __typename?: 'PlayedWhiteCard', id: string, chosen: boolean, whiteCard: { __typename?: 'WhiteCard', id: string, text: string } } };

export type PlayedCardsWithPlayersQueryVariables = Exact<{
  gameId: Scalars['String']['input'];
  round: Scalars['Int']['input'];
}>;


export type PlayedCardsWithPlayersQuery = { __typename?: 'Query', playedCardsWithPlayers: Array<{ __typename?: 'PlayedWhiteCard', player: { __typename?: 'Player', name: string }, whiteCard: { __typename?: 'WhiteCard', id: string } }> };

export type SessionUserQueryVariables = Exact<{ [key: string]: never; }>;


export type SessionUserQuery = { __typename?: 'Query', sessionUser: { __typename?: 'SessionUser', id?: string | null, gameId?: string | null, playerId?: string | null } };

export type AddGameMutationVariables = Exact<{
  game: NewGameInput;
}>;


export type AddGameMutation = { __typename?: 'Mutation', addGame: { __typename?: 'GameName', id: string, name: string, createdDate: any, state: GameState } };

export type AuthForGameMutationVariables = Exact<{
  authInfo: AuthInfoInput;
}>;


export type AuthForGameMutation = { __typename?: 'Mutation', authForGame: { __typename?: 'SessionUser', id?: string | null, playerId?: string | null, gameId?: string | null } };

export type DistributeCardsMutationVariables = Exact<{
  gameId: Scalars['String']['input'];
}>;


export type DistributeCardsMutation = { __typename?: 'Mutation', distributeCards: { __typename?: 'MutationResult', success: boolean } };

export type PlayWhiteCardMutationVariables = Exact<{
  cardId: Scalars['String']['input'];
  gameId: Scalars['String']['input'];
  playerId: Scalars['String']['input'];
}>;


export type PlayWhiteCardMutation = { __typename?: 'Mutation', playWhiteCard: { __typename?: 'WhiteCard', id: string, text: string } };

export type DropWhiteCardMutationVariables = Exact<{
  cardId: Scalars['String']['input'];
  playerId: Scalars['String']['input'];
}>;


export type DropWhiteCardMutation = { __typename?: 'Mutation', dropWhiteCard: { __typename?: 'MutationResult', success: boolean } };

export type RevealWhiteCardsMutationVariables = Exact<{
  gameId: Scalars['String']['input'];
}>;


export type RevealWhiteCardsMutation = { __typename?: 'Mutation', revealWhiteCards: { __typename?: 'MutationResult', success: boolean } };

export type ScoreWinnerMutationVariables = Exact<{
  cardId: Scalars['String']['input'];
  gameId: Scalars['String']['input'];
  playerId: Scalars['String']['input'];
}>;


export type ScoreWinnerMutation = { __typename?: 'Mutation', scoreWinner: { __typename?: 'MutationResult', success: boolean } };

export type InitializeMutationVariables = Exact<{ [key: string]: never; }>;


export type InitializeMutation = { __typename?: 'Mutation', initialize: { __typename?: 'MutationResult', success: boolean } };


export const GetSetupStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getSetupStatus"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setupStatus"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"blackCardCount"}},{"kind":"Field","name":{"kind":"Name","value":"whiteCardCount"}},{"kind":"Field","name":{"kind":"Name","value":"isInitialized"}}]}}]}}]} as unknown as DocumentNode<GetSetupStatusQuery, GetSetupStatusQueryVariables>;
export const PlayerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"player"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"player"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"ownedWhiteCards"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"text"}}]}},{"kind":"Field","name":{"kind":"Name","value":"wonBlackCards"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<PlayerQuery, PlayerQueryVariables>;
export const PlayerUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"playerUpdated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"playerUpdated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"ownedWhiteCards"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"text"}}]}},{"kind":"Field","name":{"kind":"Name","value":"wonBlackCards"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<PlayerUpdatedSubscription, PlayerUpdatedSubscriptionVariables>;
export const ChatMessagesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"chatMessages"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chatMessages"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"gameId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createdDate"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"playerName"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<ChatMessagesQuery, ChatMessagesQueryVariables>;
export const NewChatMessageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"newChatMessage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"newChatMessage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"gameId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createdDate"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"playerName"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<NewChatMessageSubscription, NewChatMessageSubscriptionVariables>;
export const SendChatMessageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"sendChatMessage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"message"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"playerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendChatMessage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"message"},"value":{"kind":"Variable","name":{"kind":"Name","value":"message"}}},{"kind":"Argument","name":{"kind":"Name","value":"gameId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}}},{"kind":"Argument","name":{"kind":"Name","value":"playerId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"playerId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<SendChatMessageMutation, SendChatMessageMutationVariables>;
export const GameNamesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"gameNames"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"gameNames"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"createdDate"}},{"kind":"Field","name":{"kind":"Name","value":"state"}}]}}]}}]} as unknown as DocumentNode<GameNamesQuery, GameNamesQueryVariables>;
export const GameAddedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"gameAdded"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"gameAdded"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"createdDate"}}]}}]}}]} as unknown as DocumentNode<GameAddedSubscription, GameAddedSubscriptionVariables>;
export const GameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"game"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"game"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"round"}},{"kind":"Field","name":{"kind":"Name","value":"state"}},{"kind":"Field","name":{"kind":"Name","value":"currentPlayer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"currentBlackCard"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"pick"}},{"kind":"Field","name":{"kind":"Name","value":"text"}}]}}]}}]}}]} as unknown as DocumentNode<GameQuery, GameQueryVariables>;
export const GameUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"gameUpdated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"gameUpdated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"round"}},{"kind":"Field","name":{"kind":"Name","value":"state"}},{"kind":"Field","name":{"kind":"Name","value":"currentPlayer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"currentBlackCard"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"pick"}},{"kind":"Field","name":{"kind":"Name","value":"text"}}]}}]}}]}}]} as unknown as DocumentNode<GameUpdatedSubscription, GameUpdatedSubscriptionVariables>;
export const GamePlayersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"gamePlayers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"gamePlayers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"gameId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"joinedDate"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"wonBlackCards"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<GamePlayersQuery, GamePlayersQueryVariables>;
export const GamePlayersUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"gamePlayersUpdated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"gamePlayersUpdated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"gameId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"joinedDate"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"wonBlackCards"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<GamePlayersUpdatedSubscription, GamePlayersUpdatedSubscriptionVariables>;
export const PlayedCardsByPlayerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"playedCardsByPlayer"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"playerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"round"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"playedCardsByPlayer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"playerId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"playerId"}}},{"kind":"Argument","name":{"kind":"Name","value":"round"},"value":{"kind":"Variable","name":{"kind":"Name","value":"round"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<PlayedCardsByPlayerQuery, PlayedCardsByPlayerQueryVariables>;
export const AllPlayedCardsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"allPlayedCards"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"round"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"allPlayedCards"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"gameId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}}},{"kind":"Argument","name":{"kind":"Name","value":"round"},"value":{"kind":"Variable","name":{"kind":"Name","value":"round"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"chosen"}},{"kind":"Field","name":{"kind":"Name","value":"whiteCard"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"text"}}]}}]}}]}}]} as unknown as DocumentNode<AllPlayedCardsQuery, AllPlayedCardsQueryVariables>;
export const PlayedWhiteCardDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"playedWhiteCard"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"round"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"playedWhiteCard"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"gameId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}}},{"kind":"Argument","name":{"kind":"Name","value":"round"},"value":{"kind":"Variable","name":{"kind":"Name","value":"round"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"chosen"}},{"kind":"Field","name":{"kind":"Name","value":"whiteCard"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"text"}}]}}]}}]}}]} as unknown as DocumentNode<PlayedWhiteCardSubscription, PlayedWhiteCardSubscriptionVariables>;
export const PlayedCardsWithPlayersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"playedCardsWithPlayers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"round"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"playedCardsWithPlayers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"gameId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}}},{"kind":"Argument","name":{"kind":"Name","value":"round"},"value":{"kind":"Variable","name":{"kind":"Name","value":"round"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"player"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"whiteCard"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<PlayedCardsWithPlayersQuery, PlayedCardsWithPlayersQueryVariables>;
export const SessionUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"sessionUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sessionUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"gameId"}},{"kind":"Field","name":{"kind":"Name","value":"playerId"}}]}}]}}]} as unknown as DocumentNode<SessionUserQuery, SessionUserQueryVariables>;
export const AddGameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"addGame"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"game"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"NewGameInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addGame"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"game"},"value":{"kind":"Variable","name":{"kind":"Name","value":"game"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"createdDate"}},{"kind":"Field","name":{"kind":"Name","value":"state"}}]}}]}}]} as unknown as DocumentNode<AddGameMutation, AddGameMutationVariables>;
export const AuthForGameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"authForGame"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"authInfo"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AuthInfoInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"authForGame"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"authInfo"},"value":{"kind":"Variable","name":{"kind":"Name","value":"authInfo"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"playerId"}},{"kind":"Field","name":{"kind":"Name","value":"gameId"}}]}}]}}]} as unknown as DocumentNode<AuthForGameMutation, AuthForGameMutationVariables>;
export const DistributeCardsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"distributeCards"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"distributeCards"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"gameId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<DistributeCardsMutation, DistributeCardsMutationVariables>;
export const PlayWhiteCardDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"playWhiteCard"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cardId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"playerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"playWhiteCard"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"cardId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cardId"}}},{"kind":"Argument","name":{"kind":"Name","value":"gameId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}}},{"kind":"Argument","name":{"kind":"Name","value":"playerId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"playerId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"text"}}]}}]}}]} as unknown as DocumentNode<PlayWhiteCardMutation, PlayWhiteCardMutationVariables>;
export const DropWhiteCardDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"dropWhiteCard"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cardId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"playerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dropWhiteCard"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"cardId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cardId"}}},{"kind":"Argument","name":{"kind":"Name","value":"playerId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"playerId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<DropWhiteCardMutation, DropWhiteCardMutationVariables>;
export const RevealWhiteCardsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"revealWhiteCards"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"revealWhiteCards"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"gameId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<RevealWhiteCardsMutation, RevealWhiteCardsMutationVariables>;
export const ScoreWinnerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"scoreWinner"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cardId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"playerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"scoreWinner"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"cardId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cardId"}}},{"kind":"Argument","name":{"kind":"Name","value":"gameId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"gameId"}}},{"kind":"Argument","name":{"kind":"Name","value":"playerId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"playerId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<ScoreWinnerMutation, ScoreWinnerMutationVariables>;
export const InitializeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"initialize"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"initialize"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<InitializeMutation, InitializeMutationVariables>;