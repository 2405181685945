import {
    onBeforeUnmount,
    ref,
    Ref,
} from 'vue';

const useSW: () => {
    swUpdated: Ref<boolean>;
    refreshApp: () => void;
} = () => {
    const refreshing = ref(false);
    const registration: Ref<ServiceWorkerRegistration | undefined> = ref(undefined);
    const swUpdated = ref(false);

    function showRefreshUI(e: Event): void {
        registration.value = (e as CustomEvent<ServiceWorkerRegistration>).detail;
        swUpdated.value = true;
    }

    document.addEventListener(
        'swUpdated', showRefreshUI, { once: true },
    );

    navigator.serviceWorker.addEventListener(
        'controllerchange', () => {
            if (refreshing.value) {
                return;
            }
            refreshing.value = true;
            window.location.reload();
        },
    );

    onBeforeUnmount(() => {
        document.removeEventListener('swUpdated', showRefreshUI);
    });

    function refreshApp(): void {
        swUpdated.value = false;

        if (!registration.value || !registration.value.waiting) { return; }
        registration.value.waiting.postMessage('skipWaiting');
    }

    return { swUpdated, refreshApp };
};

export default useSW;
